@font-face {
  font-family: "IA_icons";
  src: url("/content/commun/fonts/IA_icons.eot?t6iax1");
  src: url("/content/commun/fonts/IA_icons.eot?t6iax1#iefix") format("embedded-opentype"), url("/content/commun/fonts/IA_icons.ttf?t6iax1") format("truetype"), url("/content/commun/fonts/IA_icons.woff?t6iax1") format("woff"), url("/content/commun/fonts/IA_icons.svg?t6iax1#IA_icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.embedded-icone:before,
.embedded-icone:after,
.icone-inclus:before,
.icone-inclus:after {
  font-family: "IA_icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=icone-]:before, [class*=" icone-"]:before {
  font-family: "IA_icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-information {
  width: 604px;
  margin: 8px 0 0 12px;
  line-height: 1.29;
  color: #1b1b1b;
}

.information {
  width: 315px;
  height: 22px;
  margin: 0 479px 8px 12px;
  line-height: 1.29;
  font-size: 17px;
  font-weight: bold;
  color: #1b1b1b;
}

.icone-social-tiktok:before {
  content: "\e973";
}

.icone-conseiller-plein:before {
  content: "\e960";
}

.icone-courriel-statique:before {
  content: "\e961";
}

.icone-moins-rond:before {
  content: "\e962";
}

.icone-enveloppes:before {
  content: "\e963";
}

.icone-articles:before {
  content: "\e964";
}

.icone-vehicules-recreatifs:before {
  content: "\e965";
}

.icone-assurance-voyage:before {
  content: "\e966";
}

.icone-input-checkbox-alert:before {
  content: "\e967";
}

.icone-boat:before {
  content: "\e968";
}

.icone-Camera:before {
  content: "\e969";
}

.icone-Rappel:before {
  content: "\e96a";
}

.icone-Rendez-vous:before {
  content: "\e96b";
}

.icone-micro:before {
  content: "\e96c";
}

.icone-hub-sms:before {
  content: "\e96d";
}

.icone-ass-routiere:before {
  content: "\e96e";
}

.icone-voyage:before {
  content: "\e96f";
}

.icone-coeur-vide:before {
  content: "\e970";
}

.icone-coeur-plein:before {
  content: "\e971";
}

.icone-upload:before {
  content: "\e972";
}

.icone-outline-personne:before {
  content: "\e95c";
}

.icone-outline-cadenas:before {
  content: "\e95d";
}

.icone-outline-feuille:before {
  content: "\e95e";
}

.icone-outline-virus:before {
  content: "\e95f";
}

.icone-social-twitter:before {
  content: "\e95b";
}

.icone-application-web:before {
  content: "\e950";
}

.icone-panneau-direction:before {
  content: "\e951";
}

.icone-65-plus:before {
  content: "\e600";
}

.icone-profil:before {
  content: "\e601";
}

.icone-actuariat:before {
  content: "\e602";
}

.icone-admin-compatbilite:before {
  content: "\e603";
}

.icone-adresse:before {
  content: "\e604";
}

.icone-archives:before {
  content: "\e605";
}

.icone-auto-location:before {
  content: "\e606";
}

.icone-exclamation-formulaire:before {
  content: "\e607";
}

.icone-exclamation-rond:before {
  content: "\e608";
}

.icone-balance:before {
  content: "\e609";
}

.icone-bureau:before {
  content: "\e60a";
}

.icone-calculateur:before {
  content: "\e60b";
}

.icone-calendrier-rond:before {
  content: "\e60d";
}

.icone-roulotte:before {
  content: "\e60e";
}

.icone-chevron:before {
  content: "\e60f";
}

.icone-clef:before {
  content: "\e610";
}

.icone-collectif:before {
  content: "\e611";
}

.icone-commerce:before {
  content: "\e612";
}

.icone-marketing:before {
  content: "\e613";
}

.icone-presse:before {
  content: "\e614";
}

.icone-boussole:before {
  content: "\e615";
}

.icone-courriel:before {
  content: "\e616";
}

.icone-crochet:before {
  content: "\e617";
}

.icone-delit-fuite:before {
  content: "\e618";
}

.icone-entretien:before {
  content: "\e619";
}

.icone-moins:before {
  content: "\e61a";
}

.icone-plus:before {
  content: "\e61b";
}

.icone-liens-externes:before {
  content: "\e61c";
}

.icone-feu:before {
  content: "\e61d";
}

.icone-fleche-droite:before {
  content: "\e61e";
}

.icone-produit-fleche-droite:before {
  content: "\e61f";
}

.icone-fleche-gauche:before {
  content: "\e620";
}

.icone-formulaire-documents:before {
  content: "\e621";
}

.icone-formulaire-documents-rond:before {
  content: "\e622";
}

.icone-glossaire:before {
  content: "\e623";
}

.icone-heures:before {
  content: "\e624";
}

.icone-home:before {
  content: "\e625";
}

.icone-epargne-menu:before {
  content: "\e626";
}

.icone-service:before {
  content: "\e627";
}

.icone-print:before {
  content: "\e628";
}

.icone-individuel-celibataire:before {
  content: "\e629";
}

.icone-info-creve:before {
  content: "\e62a";
}

.icone-infolettre:before {
  content: "\e62b";
}

.icone-informatique:before {
  content: "\e62c";
}

.icone-info-tuile:before {
  content: "\e62d";
}

.icone-gestion:before {
  content: "\e62e";
}

.icone-liste-puces:before {
  content: "\e62f";
}

.icone-menu-assurance:before {
  content: "\e630";
}

.icone-menu-assurance-collective:before {
  content: "\e631";
}

.icone-cadenas:before {
  content: "\e632";
}

.icone-entreprise-groupes:before {
  content: "\e633";
}

.icone-fleche-menu:before {
  content: "\e634";
}

.icone-immobilier-menu:before {
  content: "\e635";
}

.icone-loupe:before {
  content: "\e636";
}

.icone-menu-particuliers:before {
  content: "\e637";
}

.icone-menu-prets:before {
  content: "\e638";
}

.icone-menu-services:before {
  content: "\e639";
}

.icone-moto:before {
  content: "\e63a";
}

.icone-motoneige:before {
  content: "\e63b";
}

.icone-multi-residentiels:before {
  content: "\e63c";
}

.icone-aucun:before {
  content: "\e63d";
}

.icone-periag:before {
  content: "\e63e";
}

.icone-piedpage-conseiller:before {
  content: "\e63f";
}

.icone-piedpage-questions:before {
  content: "\e640";
}

.icone-piedpage-reclamation:before {
  content: "\e641";
}

.icone-piedpage-telephone:before {
  content: "\e642";
}

.icone-etiquette:before {
  content: "\e643";
}

.icone-question:before {
  content: "\e644";
}

.icone-recherche:before {
  content: "\e645";
}

.icone-reclamation:before {
  content: "\e646";
}

.icone-refresh:before {
  content: "\e647";
}

.icone-rh:before {
  content: "\e648";
}

.icone-sante:before {
  content: "\e649";
}

.icone-scooter:before {
  content: "\e64a";
}

.icone-serie-avie:before {
  content: "\e64b";
}

.icone-service-client-rond:before {
  content: "\e64d";
}

.icone-service-client-admin:before {
  content: "\e64e";
}

.icone-web:before {
  content: "\e64f";
}

.icone-social-facebook:before {
  content: "\e650";
}

.icone-social-google-plus:before {
  content: "\e651";
}

.icone-social-linkedIn:before {
  content: "\e652";
}

.icone-social-youtube:before {
  content: "\e654";
}

.icone-soumission:before {
  content: "\e655";
}

.icone-etudiant:before {
  content: "\e656";
}

.icone-telechargement:before {
  content: "\e657";
}

.icone-telecopieur:before {
  content: "\e658";
}

.icone-telephone:before {
  content: "\e659";
}

.icone-terre:before {
  content: "\e65a";
}

.icone-toucher:before {
  content: "\e65b";
}

.icone-astuces:before {
  content: "\e65c";
}

.icone-dollar:before {
  content: "\e65d";
}

.icone-validation-ok:before {
  content: "\e65e";
}

.icone-validation-erreur:before {
  content: "\e65f";
}

.icone-vente-affaires:before {
  content: "\e660";
}

.icone-voiture-replace:before {
  content: "\e661";
}

.icone-voiture-collision:before {
  content: "\e662";
}

.icone-voiture:before {
  content: "\e663";
}

.icone-vr:before {
  content: "\e664";
}

.icone-vtt:before {
  content: "\e665";
}

.icone-hub-accident-corporel:before {
  content: "\e666";
}

.icone-hub-accident-groupes:before {
  content: "\e667";
}

.icone-hub-assurance-collective:before {
  content: "\e668";
}

.icone-hub-assurance-hypothecaire:before {
  content: "\e669";
}

.icone-hub-assurance-vie:before {
  content: "\e66a";
}

.icone-hub-assurances:before {
  content: "\e66b";
}

.icone-hub-auto:before {
  content: "\e66c";
}

.icone-hub-epargne:before {
  content: "\e66d";
}

.icone-hub-fonds:before {
  content: "\e66e";
}

.icone-hub-habitation:before {
  content: "\e66f";
}

.icone-hub-immobilier:before {
  content: "\e670";
}

.icone-hub-maladie:before {
  content: "\e671";
}

.icone-hub-pret-hypothecaire:before {
  content: "\e672";
}

.icone-hub-pret:before {
  content: "\e673";
}

.icone-hub-reee:before {
  content: "\e674";
}

.icone-hub-reer:before {
  content: "\e675";
}

.icone-hub-risques:before {
  content: "\e676";
}

.icone-hub-concessionnaires:before {
  content: "\e677";
}

.icone-hub-valeurs-mobilieres:before {
  content: "\e678";
}

.icone-conseiller-rond:before {
  content: "\e679";
}

.icone-conseiller:before {
  content: "\e67a";
}

.icone-retraite-regimes-collectifs:before {
  content: "\e67b";
}

.icone-menu-regime-retraite-collectifs:before {
  content: "\e67c";
}

.icone-brosse-dents:before {
  content: "\e67d";
}

.icone-2-brosses-dents:before {
  content: "\e67e";
}

.icone-diamant:before {
  content: "\e67f";
}

.icone-femme:before {
  content: "\e680";
}

.icone-homme:before {
  content: "\e681";
}

.icone-no-voiture:before {
  content: "\e682";
}

.icone-voiture2:before {
  content: "\e683";
}

.icone-technologie:before {
  content: "\e6af";
}

.icone-viseur:before {
  content: "\e6b0";
}

.icone-partnership:before {
  content: "\e6b1";
}

.icone-tuile-plus:before {
  content: "\e6b2";
}

.icone-tuile-x:before {
  content: "\e6b3";
}

.icone-dollar-rond:before {
  content: "\e6b4";
}

.icone-camera-rond:before {
  content: "\e6b5";
}

.icone-video-rond:before {
  content: "\e6b6";
}

.icone-web-page-rond:before {
  content: "\e6b7";
}

.icone-number-1:before {
  content: "\e6b8";
}

.icone-number-2:before {
  content: "\e6b9";
}

.icone-number-3:before {
  content: "\e6ba";
}

.icone-number-4:before {
  content: "\e6bb";
}

.icone-number-5:before {
  content: "\e6bc";
}

.icone-plus-rond:before {
  content: "\e6bd";
}

.icone-checkbox-crochet:before {
  content: "\e6be";
}

.icone-accessible:before {
  content: "\e900";
}

.icone-chat:before {
  content: "\e901";
}

.icone-graphique-financier:before {
  content: "\e902";
}

.icone-texte-information:before {
  content: "\e903";
}

.icone-cadenas-opaque:before {
  content: "\e904";
}

.icone-deux-individus:before {
  content: "\e905";
}

.icone-pouce:before {
  content: "\e906";
}

.icone-maison:before {
  content: "\e907";
}

.icone-enveloppe:before {
  content: "\e908";
}

.icone-courrier:before {
  content: "\e909";
}

.icone-telephone-angle:before {
  content: "\e90a";
}

.icone-marqueur-emplacement:before {
  content: "\e90b";
}

.icone-bulle-exclamation:before {
  content: "\e90c";
}

.icone-imprimer-opaque:before {
  content: "\e90d";
}

.icone-valise:before {
  content: "\e90e";
}

.icone-check-cta:before {
  content: "\e90f";
}

.icone-casque-ecouteur-cta:before {
  content: "\e910";
}

.icone-number-6:before {
  content: "\e911";
}

.icone-info-valise:before {
  content: "\e912";
}

.icone-boite-aux-lettres:before {
  content: "\e913";
}

.icone-securite:before {
  content: "\e914";
}

.icone-x:before {
  content: "\e915";
}

.icone-imprimer:before {
  content: "\e916";
}

.icone-calendrier:before {
  content: "\e917";
}

.icone-caisse:before {
  content: "\e918";
}

.icone-exclamation-triangle:before {
  content: "\e919";
}

.icone-service-client:before {
  content: "\e91a";
}

.icone-tirelire:before {
  content: "\e91b";
}

.icone-economie:before {
  content: "\e91c";
}

.icone-ruban:before {
  content: "\e91d";
}

.icone-cible:before {
  content: "\e91e";
}

.icone-employe:before {
  content: "\e91f";
}

.icone-fleche-cta:before {
  content: "\e920";
}

.icone-fleche-fine:before {
  content: "\e921";
}

.icone-panier:before {
  content: "\e922";
}

.icone-communication:before {
  content: "\e923";
}

.icone-formulaires:before {
  content: "\e924";
}

.icone-produits:before {
  content: "\e925";
}

.icone-heure-ouverture:before {
  content: "\e926";
}

.icone-imac:before {
  content: "\e927";
}

.icone-play:before {
  content: "\e928";
}

.icone-avion:before {
  content: "\e929";
}

.icone-actualiser:before {
  content: "\e92a";
}

.icone-input-checkbox:before {
  content: "\e92b";
}

.icone-input-checkbox-checked:before {
  content: "\e92c";
}

.icone-input-radio:before {
  content: "\e92d";
}

.icone-input-radio-checked:before {
  content: "\e92e";
}

.icone-ampoule:before {
  content: "\e92f";
}

.icone-instagram:before {
  content: "\e930";
}

.icone-play-youtube:before {
  content: "\e931";
}

.icone-play-cercle:before {
  content: "\e932";
}

.icone-oeil:before {
  content: "\e933";
}

.icone-dollar-gras:before {
  content: "\e934";
}

.icone-coeur:before {
  content: "\e935";
}

.icone-chevron-dropdown:before {
  content: "\e936";
}

.icone-download:before {
  content: "\e937";
}

.icone-open-in-new:before {
  content: "\e938";
}

.icone-cherche:before {
  content: "\e939";
}

.icone-datepicker:before {
  content: "\e93a";
}

.icone-warning:before {
  content: "\e93b";
}

.icone-poste-canada:before {
  content: "\e93c";
}

.icone-check-fin:before {
  content: "\e93d";
}

.icone-ajout:before {
  content: "\e93e";
}

.icone-plus-rond-plein:before {
  content: "\e93f";
}

.icone-sup-rond-plein:before {
  content: "\e940";
}

.icone-poubelle:before {
  content: "\e941";
}

.icone-drapeau-damier:before {
  content: "\e942";
}

.icone-drapeau:before {
  content: "\e943";
}

.icone-documents:before {
  content: "\e944";
}

.icone-etoile-pleine:before {
  content: "\e945";
}

.icone-etoile-vide:before {
  content: "\e946";
}

.icone-check-rond:before {
  content: "\e947";
}

.icone-plus-material:before {
  content: "\e948";
}

.icone-outline-enveloppe:before {
  content: "\e949";
}

.icone-drapeau-course:before {
  content: "\e94f";
}

.icone-question-reponse:before {
  content: "\e94a";
}

.icone-participant-ajouter:before {
  content: "\e94b";
}

.icone-participant:before {
  content: "\e94c";
}

.icone-thumb-up:before {
  content: "\e94d";
}

.icone-new-releases:before {
  content: "\e94e";
}

.icone-groups:before {
  content: "\e952";
}

.icone-file-upload:before {
  content: "\e953";
}

.icone-badge:before {
  content: "\e954";
}

.icone-account-balance-wallet:before {
  content: "\e955";
}

.icone-volunteer-activism:before {
  content: "\e956";
}

.icone-ampoule1:before {
  content: "\e957";
}

.icone-open-new-page:before {
  content: "\e958";
}

.icone-report:before {
  content: "\e959";
}

.icone-social-pinterest:before {
  content: "\e95a";
}

.icone-wealth:before {
  content: "\e974";
}